import * as React from 'react'
import { RecentBlogs } from '../components/recent-blogs'
import { DefaultLayout } from '../layouts/main-layout'

export default function Insights() {
  return (
    <DefaultLayout
      name="Insights"
      description="BridgePhase, LLC: Where Innovation and Performance Converge."
    >
      <section>
        <h2>Insights</h2>
        <p>
          Our team of experts understand how important shared knowledge and open communities are for the advancement of
          technology and its effective use within our society. From business trends and best practices to deep technical
          insights and case studies, our articles offer insightful content that will guide your organization to
          increased productivity and success.
        </p>
        <RecentBlogs />
      </section>
    </DefaultLayout>
  )
}

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { BlurbWithImage } from './blurb-with-image'

interface Blog {
  date: string
  description: string
  excerpt: string
  logo?: string
  slug: string
  title: string
}

interface BlogNode {
  frontmatter: Blog
}

const BlogSnippet: React.FC<Blog> = ({
  description,
  logo,
  slug,
  title,
}: Blog) => {
  const logoToDisplay = logo
    ? `/images/${logo}`
    : '/images/offerings/career-development.png'
  return (
    <div>
      <BlurbWithImage
        description={description}
        image={logoToDisplay}
        title={title}
        readMoreLink={`/insights/${slug}`}
      />
    </div>
  )
}
const Blogs = ({ data }: { data: BlogNode[] }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {data.map((node) => {
        const blogData = node.frontmatter
        return <BlogSnippet {...blogData} />
      })}
    </div>
  )
}
export const RecentBlogs = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            limit: 10
            sort: { fields: frontmatter___date, order: DESC }
            filter: { frontmatter: { active: { eq: true } } }
          ) {
            nodes {
              frontmatter {
                date
                description
                logo
                slug
                title
              }
              id
              excerpt
            }
          }
        }
      `}
      render={(data) => <Blogs data={data.allMarkdownRemark.nodes} />}
    />
  )
}
